export const FINANCIAL_ADVISER = 'financial_adviser';
export const MORTGAGE_ADVISOR = 'mortgage_advisor';
export const LEGAL_ADVISER = 'legal_adviser';
export const ACCOUNTANT = 'accountant';

export const FRIENDLY_NAME = {
    IFA: 'Independent Financial Adviser',
    FOCUSED: 'Focussed Independent Financial Adviser',
    FA_RESTRICTED: 'Restricted Financial Adviser',
    MORTGAGE_ADVISER: 'Independent Mortgage Adviser',
    MORTGAGE_LIMITED: 'Limited Mortgage Adviser',
    FINANCIAL_COACH: 'Financial Coach',
    PROTECTION_WHOLE_OF_MARKET: 'Whole of Market Protection Adviser',
    PROTECTION_LIMITED: 'Limited Protection Adviser',
    EQUITY_RELEASE_WHOLE_OF_MARKET: 'Whole of Market Equity Release Adviser',
    EQUITY_RELEASE_LIMITED: 'Limited Equity Release Adviser',
};

export const PROFESSIONAL_TYPE = {
    FINANCIAL_COACH: 'financial_coach',
    PROTECTION_WHOLE_OF_MARKET: 'protection_whole_of_market',
    PROTECTION_LIMITED: 'protection_limited',
    EQUITY_RELEASE_WHOLE_OF_MARKET: 'equity_release_whole_of_market',
    EQUITY_RELEASE_LIMITED: 'equity_release_limited',
    MORTGAGE_ADVISOR: 'mortgage_adviser',
    MORTGAGE_LIMITED: 'mortgage_limited',
    IFA: 'ifa',
    FA_RESTRICTED: 'fa_restricted',
    ACCOUNTANT: 'accountant',
    LEGAL_ADVISER: 'legal_adviser',
};

export const SERVICE_TYPE ={
    FINANCIAL_ADVISER_SERVICES : 'financial_adviser_services',
    ACCOUNTANT_BUSINESS_SERVICES: 'accountant_business_services',
    LEGAL_ADVISER_SERVICES: 'legal_adviser_services',
}


export const URL_COMPONENTS = {
    FA: 'financial-advisor-ifa',
    FA_MA_CANONICAL: 'IFA-financial-advisor-mortgage',
    MA: 'mortgage-advisor',
    SOLICITOR: 'solicitor',
    ACCOUNTANT: 'accountant',
    PROTECTION_ADVISOR: 'protection-advisor',
    EQUITY_RELEASE: 'equity-release-advisor',
    FINANCIAL_COACH: 'financial-coach'
};


export const URL_COMPONENTS_NEW = {
    FA: 'financial-advisor-ifa',
    FA_MA_CANONICAL: 'IFA-financial-advisor-mortgage',
    MA: 'mortgage-advisor',
    SOLICITOR: 'solicitors',
    ACCOUNTANT: 'accountant',
    PROTECTION_ADVISOR: 'protection-advisor',
    EQUITY_RELEASE: 'equity-release-advisor',
    FINANCIAL_COACH: 'financial-coach'
};

export const LEGAL_ADVISER_ID = 3;
export const ACCOUNTANT_ID = 4;
export const FINANCIAL_ADVISER_ID = 5;

export const singularVerticalName = {
    [LEGAL_ADVISER_ID]: 'legal adviser',
    [ACCOUNTANT_ID]: 'accountant',
    [FINANCIAL_ADVISER_ID]: 'adviser'
};

export const pluralVerticalName = {
    [LEGAL_ADVISER_ID]: 'solicitors',
    [ACCOUNTANT_ID]: 'accountants',
    [FINANCIAL_ADVISER_ID]: 'advisers'
};

export const ROUTER_URL_OPTIONS = Object.values(URL_COMPONENTS).join('|');
export const ROUTER_VERTICAL_OPTIONS = Object.values(URL_COMPONENTS).filter(vertical => {
    return [URL_COMPONENTS.FA_MA_CANONICAL, URL_COMPONENTS.SOLICITOR, URL_COMPONENTS.ACCOUNTANT].includes(vertical);
}).join('|');


export default {
    FINANCIAL_ADVISER: FINANCIAL_ADVISER,
    MORTGAGE_ADVISOR: MORTGAGE_ADVISOR,
    LEGAL_ADVISER: LEGAL_ADVISER,
    ACCOUNTANT: ACCOUNTANT
};
